@import url("https://fonts.googleapis.com/css?family=Poppins");
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
// .popover{
//   width: 100% !important;
//   margin: 0 10px;
// }
// .correct-pass-key {
//   color: $salem;
//   font-size: 14px;
//   font-weight: 400;
// }
.wrong-pass-key {
  color: $vilontRed;
  font-size: 14px;
  font-weight: 400;
}
.warning-text {
  color: $vilontRed;
  font-size: 12px;
  font-weight: 400;
}
.mandate-star {
  color: $vilontRed;
  margin: 1px;
}
.text-whatsapp {
  font-size: 12px !important;
  color: $salem;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loader-container-none {
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.spinner-img {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-image-size {
  height: 40px;
}
.text-link {
  color: $grey;
  font-size: 13px;
  font-weight: 700;
  font-style: italic;
  text-decoration: none !important;
  &:hover {
    color: $vilontRed;
  }
}
.prod-lease-bg {
  background: $whitesmoke;
  border: 1px solid $whitesmoke;
  font-size: 14px;
  font-weight: 600;
  color: $vilontRed;
  padding: 3px 8px;
  border-radius: 5px;
  box-shadow: 2px 3px 8px rgba(76, 84, 84, 0.7);
}
.prod-cat-bg {
  background: $whitesmoke;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: $litGreen;
  padding: 0 4px;
}
.form-collapse-card-header {
  background: $whitesmoke;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: $vilontRed;
}
.menu-link-notification {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1px 5px;
}
.menu-image-avatar{
  margin-right: 15px;
}
.cursor-pointer {
  cursor: pointer;
}
.padding-zero{
  padding: 0;
}
.buy-main-label {
  color: $grey !important;
  background-color: $whitesmoke !important;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 4px !important;
  padding: 8px 10px;
  border-radius: 2px;
}
.margin-zero{
  margin: 0;
}
.align-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.icon-buy-small{
  color: $litGreen;
  font-size: 12px;
}
.icon-buy-medium{
  color: $litGreen;
  font-size: 14px;
}
.icon-buy-medium-dis{
  color: $grey;
  font-size: 14px;
}
.icon-buy-large{
  color: $litGreen;
  font-size: 16px;
}
.listing-price-text {
  color: $grey;
  font-weight: 400;
  font-size: 14px !important;
}
.cat-check-text {
  color: $vilontRed !important;
  font-size: 14px;
  font-weight: 600;
}
.link-text-italic {
  color: $vilontRed !important;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
}

.all-text {
  color: $onyx !important;
  font-size: 15px;
  font-weight: 500;
}
.buy-check-box {
  color: $litGreen;
  margin-top: 5px;
  outline: 1px solid $litGreen !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $litGreen;
  margin-right: 10px;
  width: 13px;
  height: 13px;
  border-radius: 1px !important;
}
.buy-check-box:checked {
  background-color: $litGreen !important;
  border: 1px solid $litGreen !important;
  box-shadow: 0 0 1px $litGreen;
}

.buy-check-box:focus,
.label::after,
.label.buy-check-box:focus,
.buy-check-box:checked::after,
.buy-check-box:not(.disabled):not(.disabled):active:focus {
  color: $grey;
  outline: 1px solid $grey !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $grey;
}
.button-filled {
  background: $litGreen;
  border: none;
  border-radius: 3px;
  color: $white !important;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 15px;
  margin-bottom: 3px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: $deepBlue !important;
  }
  &:hover {
    background-color: $deepBlue;
    color: $white !important;
  }
}
.button-filled[disabled] {
  background: transparent;
  color: $grey !important;
  border: 1px solid $grey;
}
.button-open {
  background: $white;
  border:1px solid $litGreen;
  border-radius: 3px;
  color: $deepBlue !important;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 15px;
  margin-bottom: 3px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $deepBlue !important;
    background-color: $white !important;
  }
  &:hover {
    background-color: $white;
    color: $litGreen !important;
    border:1px solid $deepBlue;
  }
}
.button-open[disabled] {
  background: transparent;
  color: $grey !important;
  border: 1px solid $grey;
}
.border-up-listing {
  border-top: 1px solid $litGreen;
  background-color: transparent !important;
}
.button-round {
  background-color: $deepBlue !important;
  border: none;
  border-radius: 50%;
  padding: 6px 15px;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $litGreen !important;
  }
  &:hover {
    background-color: $litGreen !important;
    color: $white !important;
  }
}

.button-round[disabled] {
  color: $white !important;
  background-color: $grey !important;
}

.button-icon {
  background: transparent;
  border: none;
  color: $litGreen !important;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $vilontRed !important;
  }
}
.button-icon[disabled] {
  color: $grey !important;
  background-color: transparent;
}
// Inline Button Text Input
.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-wrapper input[type="text"] {
  border: 1px solid $litGreen !important;
  background: $white !important;
  border-radius: 25px !important;
  height: 45px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $grey !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $grey !important;
  }
}

.input-wrapper button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  background-color: transparent;
  color: $deepBlue;
  padding: 0 13px;
  cursor: pointer;
  border-radius: 50%;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    background-color: transparent;
    color: $vilontRed !important;
  }
}

// Inline Button Text Input
.card-image{
  border-radius: 1px;
  width: 100%;
  height: 230px;
}
.card-lite {
  background-color: $white;
  border: 1px solid $whisper;
  box-shadow: 2px 5px 10px rgba(21, 22, 23, 0.1);
  border-radius: 2px;
}
.route-margin{
  margin-top: 5rem;
}
.rounded-image {
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid $whitesmoke;
  padding: 2px;
}
.logo-bg {
  height: 45px;
  width: auto;
}
.text-lite-grey-grey {
  color: $grey;
  font-size: 13px;
  font-weight: 400;
}
.text-super-lite {
  color: $grey;
  font-size: 12px;
  font-weight: 400;
}
.text-lite-grey-grey-small {
  color: $grey;
  font-size: 12px;
  font-weight: 400;
}
.text-lite-dark {
  color: $deepBlue;
  font-size: 14px;
  font-weight: 400;
}
.text-lite-grey {
  color: $litGreen;
  font-size: 14px;
  font-weight: 400;
}
.text-bold-grey {
  color: $litGreen;
  font-size: 14px;
  font-weight: 400;
}
.text-center-aligned {
  text-align: center !important;
}

.align-text-justify {
  text-align: justify !important;
}
.bg-header {
  background: $white;
  border: none;
}
.form-card-header {
  background: $white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: $onyx;
  padding: 0;
  border-radius: 1px;
}

.buy-input {
  border: 1px solid $litGreen !important;
  background: $white !important;
  border-radius: 1px !important;
  height: 45px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $grey !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $grey !important;
  }
}

.buy-input[disabled] {
  background: $whisper !important;
  border-color: $whisper!important;
  &:hover {
    border-color: $whisper !important;
  }
  &:focus {
    box-shadow: none;
    border-color: $whisper !important;
    color: $onyx;
    background-color: $whisper !important;
  }
}
.buy-input-oval {
  border: 1px solid $litGreen !important;
  background: $white !important;
  border-radius: 20px !important;
  height: 45px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $grey !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $grey !important;
  }
}

.buy-input-oval[disabled] {
  background: $whisper !important;
  border-color: $whisper!important;
  &:hover {
    border-color: $whisper !important;
  }
  &:focus {
    box-shadow: none;
    border-color: $whisper !important;
    color: $onyx;
    background-color: $whisper !important;
  }
}
.buy-textarea {
  border: 1px solid $litGreen !important;
  background: $white !important;
  border-radius: 1px !important;
  height: 85px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $grey !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $grey !important;
  }
}

input[type="text" i]::placeholder {
  color: $grey;
}
.nav-tabs {
  border-bottom: none !important;
}
.pd {
  padding: 5px;
}
.pd-1 {
  padding: 10px;
}
.mx-1{
  margin: 0 5px;
}
.mx-2{
  margin: 0 10px;
}
.mx-3{
  margin: 0 1rem;
}
.mx-4{
  margin: 0 2rem;
}
.mx-5{
  margin: 0 3rem;
}
.ml {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}
.mr {
  margin-right: .5rem;
}
.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}
.margin-zero {
  margin: 0 !important;
}
.padding-zero {
  padding: 0 !important;
}