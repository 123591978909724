@import '../../../styles/scss/index.scss';

// .navbar {
//   background-color: #ffffff;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-size: 1.2rem;
//   // position: fixed;
//   top: 0;
//   width: 100%;
//   z-index: 1030;
// }

.nav-logo {
  color: $deepBlue;
  align-items: center;
  // margin: 20px !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  margin: 0 10px !important;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: left;
  padding-top: 10px;
  margin-right: 5px;
}

.nav-links {
  font-size: 16px;
  font-style: initial;
  color: $onyx;
  text-decoration: none;
  height: 100%;
  border-bottom: 1px solid transparent;
}

.nav-product-header {
  font-size: 16px;
  font-style: initial;
  color: $onyx;
}

.show>.nav-links:focus {
  color: $onyx;
}

.show>.nav-links::after {
  color: $onyx;
}

.nav-links:hover {
  color: $onyx !important;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 28px;
  // margin-right: 1.5rem;
  // margin-left: 1.5rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 0px;
  width: 0;
  background: $onyx;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: $onyx;
}

.nav-icon {
  display: none;
}

.nav-icon:focus {
  color: $onyx;
}

.nav-close-btn {
  font-size: 32px !important;
  margin-right: 5px;
}

.nav-open-btn {
  color: $onyx;
}

.nav-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}


.nav-icon-link {
  color: $deepBlue !important;
  margin-right: 5px;
  font-size: .71em;
  margin-bottom: 1px !important;
}

.nav-icon-link-btn {
  color: $deepBlue;
  margin-right: 7px;
  font-size: .71em;
  margin-bottom: 1px
}

.nav-icon-toggle {
  color: $deepBlue !important;
  margin-left: 5px;
  font-size: 14px !important;
  margin-bottom: 3px
}

.nav-text-header {
  color: $deepBlue;
  font-size: 15px;
  font-weight: 400;
}

.quote-nav-main-left {
  background-color: $onyx;
  color: $white;
  font-weight: 500;
}
.quote-nav-main-middle {
  background-color: $litGreen;
  color: $white;
  font-weight: 700;
}
.margin-nav-desk{
  margin-right: 10px;
}
@media screen and (max-width: 586px) {
  .navbar {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
}
  .margin-nav-desk{
    margin-right: 1px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    position: fixed;
    width: 100%;
    top: 65px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .icon-header-text {
    text-decoration: inherit;
    color: $onyx;
    font-size: 15px;
    padding: 12px;
  }

  .nav-menu.active {
    background: $white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-right: 30px !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .nav-item {
    line-height: 40px !important;
    margin-left: 1px !important;
  }

  li {
    border-bottom: none;
    width: 100% !important;
  }

  .nav-item .active {
    color: $onyx;
    border: none;
  }

  .nav-links {
    padding: 1rem 0 1rem 0;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 30%);
    font-size: 1.8rem;
    cursor: pointer;
    color: $onyx;
  }
  .nav-item-login {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .nav-icon-link-btn {
    color: $onyx !important;
    margin-right: 10px !important;
  }
}
