$white: #ffffff;
$whitesmoke: #F6F6F6;
$whisper: #d7d5d5;
$grey: #868585;
$onyx: #383838;
$deepBlue: #0F2E42;
$litNeon: #55f6e3;
$litGreen: #4BA399;
$vilontRed: #E83323;
$salem: #0A8242;
