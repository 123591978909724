@import "../../../styles/scss/index.scss";

.buy-row-profile-tab-mobile {
  display: none;
}
.buy-image-dim {
  width: 100% !important;
  height: 100px !important;
}
.buy-row-profile-tab {
  display: block;
}

.buy-column-profile-tab-left {
  float: left;
}
.buy-column-profile-tab-right {
  float: right;
}
.buy-profile-nav-tabs {
  color: $litNeon !important;
  background-color: $deepBlue !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  margin: 1px 10px !important;
  padding: 5px 15px;
}

.buy-profile-nav-tabs.active {
  color: $deepBlue !important;
  background: $white !important;
  letter-spacing: 1px;
  border: none;
  border-bottom: 4px solid $vilontRed !important;
}

.buy-product-preview {
  margin-right: 30px;
  margin-bottom: 10px;
  // width: 180px;
  max-height: 100px !important;
  padding: 10px;
  border: 1px solid $whisper;
}
.dash-card-header {
  padding: 2px !important;
  border: 6px solid $deepBlue;
  border-radius: 4px;
}
.min-text-card-padding {
  min-height: 50px !important;
}
.project-preview {
  width: 200px;
  height: 200px;
}
.proj-config-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.proj-search-box {
  border: 1px dashed #b6bed1;
  background-color: #fdfdff;
  border-radius: 4px;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.project-admin-image {
  border-radius: 1px;
  width: 100%;
  height: 135px;
}
.admin-status-green {
  border: 1px solid $salem;
  background-color: $white;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 13px;
  font-weight: 600;
  color: $salem;
}
.admin-status-red {
  border: 1px solid $vilontRed;
  background-color: $white;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 13px;
  font-weight: 600;
  color: $vilontRed;
}
.admin-status-grey {
  border: 1px solid $grey;
  background-color: $white;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 13px;
  font-weight: 600;
  color: $grey;
}
.admin-card-image-container {
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    border-radius: 1px;
  }

  .image-navigation-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // background-color: rgba(30, 29, 29, 0.3);
    background-color: transparent;
    color: $white;
    border: none;
    border-radius: 50%;
    padding: 3px 11px 3px 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(30, 29, 29, 0.3);
      color: $white;
    }
    &.left {
      left: 1px;
    }

    &.right {
      right: 1px;
    }
  }
}
@media screen and (max-width: 576px) {
  .buy-row-profile-tab-mobile {
    display: block;
    // margin-top: 10px;
  }

  .buy-row-profile-tab {
    display: none;
  }

  .buy-tab-profile-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .buy-profile-tab {
    border: none !important;
  }
  .buy-profile-nav-tabs {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 10px !important;
    padding: 10px 18px;
    border: none !important;
  }
  .buy-profile-nav-tabs.active {
    color: $deepBlue !important;
    background: $whisper !important;
    letter-spacing: 1px;
    border: none;
    border-bottom: 2px solid $vilontRed !important;
  }
}
