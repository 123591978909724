$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
h1 {
  font-size: 75px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
h2 {
  font-size: 55px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
h3 {
  font-size: 40px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
h4 {
  font-size: 30px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
h5 {
  font-size: 24px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
h6 {
  font-size: 20px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
p {
  font-size: 17px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
span {
  font-size: 15px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}