@import "../../../styles/scss/index.scss";

.home {
  position: relative;
  width: 100%;
  height: auto;
}
.image-wrapper {
  position: relative;
  width: 100%;
}
.home-image {
  width: 100%;
  height: 90vh;
  display: block;
}
.home-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 70%;
}

.know-buy-header {
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: $onyx;
}

.home p {
  font-size: 2.3rem;
  color: $deepBlue;
  padding-top: 20px;
}

.home-content button {
  display: block;
  font-size: clamp(14px, 1.5vw, 18px);
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  background: transparent;
  color: #fff;
  margin: 50px auto 0;
  padding: 12px 20px;
  cursor: pointer;
}

.button-know-home {
  border: 1px solid $litGreen !important;
  background-color: $litGreen !important;
  border-radius: 55px !important;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
}
.button-know-home-lite {
  border: 1px solid $litGreen !important;
  background-color: $white !important;
  border-radius: 55px !important;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: $deepBlue !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-color: $litGreen !important;
    color: $white !important;
  }
}
.inbox-align-button {
  background-color: transparent;
  border: none;
}
.text-main-header {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: $onyx;
  font-weight: 600;
}
.text-main-lite {
  font-size: 16px;
  color: $whisper;
  font-weight: 400;
}
.icon-header {
  font-size: 36px;
  color: $deepBlue;
}
.text-on-demand {
  font-size: 16px;
  color: $deepBlue;
  font-weight: 600;
}
.margin-top-header {
  margin-top: 2rem;
}
.margin-top-video {
  margin-top: 15rem;
}
.icons {
  padding: 0 15%;
  display: flex;
  justify-content: space-around;
  margin: 30px 0px;
}
.icons img {
  width: 106px;
  height: auto;
}
.img-safety {
  max-width: 250px;
  height: auto;
}
.video-style {
  width: 350px !important;
  height: auto !important;
}
section.animate {
  -webkit-animation: bounceIn 1s;
  animation: bounceIn 1s;
}
.GuaranteeSection_granatee-container__3Cm86 {
  background-color: #f5f5f5;
  padding: 5px;
}
.GuaranteeSection_granatee-container__3Cm86
  .GuaranteeSection_safety-inner-contain__jsY5y {
  -moz-box-pack: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 5px;
}
.GuaranteeSection_granatee-container__3Cm86
  .GuaranteeSection_safety-inner-contain__jsY5y
  .GuaranteeSection_icon-container__VlCib {
  height: 51px;
  width: 160px;
}
.GuaranteeSection_granatee-container__3Cm86
  .GuaranteeSection_stripes-border__3kXa6 {
  border-right: 1px solid #ccc;
}
.GuaranteeSection_granatee-container__3Cm86
  .GuaranteeSection_garanteeStripes-tabs__HyjU3 {
  padding: 5px 35px;
}

.home-video {
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 5px;
}
// image gallery scss

.image-gallery-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: transparent;
}

.image-gallery {
  display: flex;
  position: relative;
}

.image-gallery img {
  width: 150px;
  height: 80px;
  margin-right: 6rem !important;
}

.human-label {
  background-color: $white;
  color: $deepBlue;
  font-size: 16px;
  font-weight: 900;
  padding: 7px 20px;
}
.product-intro-label {
  background-color: $white;
  color: $deepBlue;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px;
}
.home-filter-icon{
  display: block;
}
// Image galery SCSS
//footer start
.footer-wds {
  clear: both;
  position: relative;
  bottom: 0;
  padding: 10px;
}
.text-footer-header {
  color: $deepBlue;
  font-size: 20px;
  font-weight: 500;
}
.copyright {
  color: $grey;
  font-weight: 300;
}
.text-copyright-mail {
  color: $deepBlue;
  font-weight: 400;
  &:hover {
    color: $whisper;
  }
}
//footer ends
.card-image-container {
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 300px;
    border-radius: 1px;
  }

  .image-navigation-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // background-color: rgba(30, 29, 29, 0.3);
    background-color: $litGreen;
    color: $white;
    border: none;
    border-radius: 50%;
    padding: 3px 11px 3px 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(30, 29, 29, 0.3);
      color: $white;
    }
    &.left {
      left: 1px;
    }

    &.right {
      right: 1px;
    }
  }
}

.card-text {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  color: $onyx;
}
.navigation-button {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background-color: $onyx;
    border-radius: 50%;
    color: $litGreen;
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    padding: 1px 12px 2px 13px;

    &:hover {
      background-color: $litGreen;
      color: $onyx;
    }

    &:disabled {
      background-color: $whisper;
      color: $grey;
      cursor: not-allowed;
    }
  }
}

@media only screen and (max-width: 568px) {
  .margin-top-video {
    margin-top: 15rem;
  }
  .home-content {
    width: 90%;
  }
  .home-image {
    width: 100%;
    display: block;
    height: 110vh;
  }
  .know-buy-header {
    font-size: 2.1rem !important;
    font-weight: 600;
    margin-bottom: 1px;
    color: $deepBlue;
    // margin-top: 18rem;
  }
  .home p {
    font-size: 1.3rem;
    color: $deepBlue;
    padding-top: 20px;
  }
  .icons {
    padding: 0 1%;
    display: flex;
    justify-content: space-around;
    margin: 20px 6px;
  }
  .icons img {
    width: 50px;
    height: auto;
    margin: 3px 10px !important;
  }
}
