@import "../../../../styles/scss/index.scss";

.margin-height-cards{
  margin-top: 3rem;
}
.buy-subheader{
  font-size: 18px;
  font-weight: 600;
  color: $onyx;
}
.buy-subtext{
  font-size: 16px;
  font-weight: 700;
  color: $onyx;
}
.box-buy-cat{
  border: 1px solid $whisper;
  border-radius: 3px;
  padding: 2px 8px;
  font-size: 13px;
  font-weight: 800;
  color: $grey;
}
.card-filter-comm {
  background-color: $litGreen;
  border: 1px solid $salem;
  box-shadow: 2px 5px 10px rgba(21, 22, 23, 0.1);
  padding: 15px 2px;
  border-radius: 1px;
}
.margin-l-r-mobile{
  margin: 0 20px
}
.align-fullview-tab {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
@media only screen and (max-width: 568px) {
    .margin-top-des {
      margin-top: 2rem;
      margin-left: 2px;
      margin-right: 2px;
    }
    .scroll-projects {
      height: 40vh; /* Set to viewport height */
      overflow: auto; /* Enable scrolling */
      -webkit-overflow-scrolling: touch; 
    }
    .margin-top-up {
      margin-top: 8rem;
    }
    .margin-l-r-mobile{
      margin: 0 1px
    }
    .align-fullview-tab {
      display:contents;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .margin-top-dropdwn {
      margin-top: 1rem !important;
    }
}