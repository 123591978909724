@import '../../styles/scss/index.scss';

.protype-visibility-mobile {
    display: none;
}
.protype-visibility-desk {
    display: block;
}

@media only screen and (max-width: 568px) {
    .protype-visibility-mobile {
        display: block;
    }
    .protype-visibility-desk {
        display: none;
    }
}