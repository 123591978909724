@import "../../../styles/scss/index.scss";

.unauthorized-container {
  color: $vilontRed !important;
}
.error-header {
  font-size: 150px;
  font-weight: 700;
  color: $vilontRed !important;
}
.error-sub-header {
  font-size: 50px;
  font-weight: 400;
}

.vert-center-unauthorized {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-10%, -40%);
}
.login-back {
  padding: 0.3rem 1rem 0.3rem 1rem;
  color: $deepBlue;
  text-decoration: none;
}
@media screen and (max-width: 576px) {
  .vert-center-unauthorized {
    margin: 0;
    position: absolute;
    top: 50% !important;
    left: 40% !important;
    transform: translate(-30%, -40%) !important;
  }
}
