@import "../../../styles/scss/index.scss";

.login-header {
  color: $grey;
  font-size: 20px;
  font-weight: 500;
}
.login-tagline-text {
  color: $grey;
  font-size: 18px;
  font-weight: 500;
}
.signup-discom-tabs {
  background-color: transparent !important;
  margin: 0 8px !important;
  padding: 4px 3px !important;
  border: none;
  &:hover {
    background: $whisper !important;
    box-shadow: 1px 6px 8px rgb(0 55 183 / 8%);
  }
}
.signup-discom-tabs.active {
  background-color: $whisper !important;
  margin: 0 8px !important;
  padding: 4px 3px !important;
  border: none;
  border-bottom: 1px solid $litGreen !important;
  &:hover {
      background-color: $whisper !important;
  }
}
.discom-signup-bg {
    background-color: $whisper !important;
    margin: 1px 1px !important;
    padding: 5px 0;
    border-radius: 2px;
}
.discom-signup-buz {
  background-color: $whisper !important;
  margin: 1px 1px !important;
  padding: 5px 0;
  border: 1px solid $whisper;
  border-radius: 4px;
}

.discom-liting-text{
  font-size: 14px;
  font-weight: 400;
  color: $onyx;
}

.text-signup-discom-header {
  color: $onyx;
  font-weight: 600;
  font-size: 13px;
}
.text-signup-discom-icon {
  height: 40px;
  width: 40px;
}
@media screen and (max-width: 576px) {
  .margin-login-column {
    margin-top: 15px;
  }
  .signup-discom-tabs {
    margin: 0 4px !important;
    padding: 4px 2px !important;
  }
  .signup-discom-tabs.active {
    margin: 0 8px !important;
    padding: 4px 2px !important;
  }
}
