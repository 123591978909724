@import "../../styles/scss/index.scss";

.navbar-admin {
  background: $white;
  position: fixed !important;
  top: 0;
  margin: 0 !important;
  width: 100%;
  z-index: 1030;
  box-shadow: 2px 5px 10px rgba(21, 22, 23, 0.1);
}
.sadmin-user-name-text {
    font-size: 14px !important;
    font-weight: 600;
    color: $onyx;
  }
  .sadmin-grey-icon {
    font-size: 16px !important;
    font-weight: 700;
    color: $grey;
}